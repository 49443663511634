<template>
  <div>
    <h2 class='text-xl font-semibold'>
      {{ abstractSubmissionDetailsFormTitle }}
    </h2>
    <div v-if='abstractSubmissionDetailsFormHelperHtmlContent'
      v-html='abstractSubmissionDetailsFormHelperHtmlContent'>    
    </div>
    <edit-form-field
      class='mt-4 mb-6 w-full lg:w-2/3' 
      v-for='formField in abstractSubmissionDetailsFormFields'
      :key='formField.keyName'
      :edit-form-field='formField'
      :value='fieldValue(formField.category, formField.keyName)'
      :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
      :is-horizontal-layout='isFormFieldsHorizontalLayout'
      :requirement-indicator-helper-text='requirementIndicatorHelperText'
      @update-edit-form-field-value='updateEditFormFieldValue(formField.category, formField.keyName, ...arguments)' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import camelCase from 'lodash/camelCase'
import jsonLogic  from 'json-logic-js'

export default {
  name: 'AbstractSubmissionDetails',
  components: {
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
  },
  computed: {
    ...mapGetters('events',[
      'showingEventAbstractSubmissionsConfigurations',
    ]),
    ...mapFields('abstracts', [
      'editingAbstractSubmission'
    ]),
    abstractSubmissionDetailsConfigs () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDetailsConfigs) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDetailsConfigs : null
    },
    abstractSubmissionDetailsFormFields () {
      return (this.abstractSubmissionDetailsConfigs && this.abstractSubmissionDetailsConfigs.formFields) ?
        this.abstractSubmissionDetailsConfigs.formFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {abstractSubmission: this.editingAbstractSubmission, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    abstractSubmissionDetailsFormTitle () {
      return (this.abstractSubmissionDetailsConfigs && this.abstractSubmissionDetailsConfigs.formTitle) ? this.abstractSubmissionDetailsConfigs.formTitle : 'Abstract Details'
    },
    abstractSubmissionDetailsFormHelperHtmlContent () {
      return (this.abstractSubmissionDetailsConfigs && this.abstractSubmissionDetailsConfigs.formHelperHtmlContent) ? this.abstractSubmissionDetailsConfigs.formHelperHtmlContent : ''
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.showRequirementIndicatorOnTop) ? this.showingEventAbstractSubmissionsConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.requirementIndicatorHelperText) ? this.showingEventAbstractSubmissionsConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    isFormFieldsHorizontalLayout () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.isFormFieldsHorizontalLayout) ? this.showingEventAbstractSubmissionsConfigurations.isFormFieldsHorizontalLayout : false
    },
  },
  methods: {
    updateEditFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          this.editingAbstractSubmission[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.editingAbstractSubmission[arr[0]] = value
        }
      }
    },                                 
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          val = this.editingAbstractSubmission[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.editingAbstractSubmission[arr[0]]
        }
      }
      return val
    },
  },
}
</script>
